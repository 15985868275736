import React, { ReactElement } from "react";
import MenuCollapseItem from "@components/header/menu/MenuCollapseItem";
import { Trans } from "react-i18next";
import Icon from "@components/icons/Icon";
import MenuContractItems from "@components/header/menu/MenuContractItems";

type MenuItemProps = {
  onClick: () => void;
  onClose: () => void;
  open: boolean;
};

const MenuCcav = ({ open, onClick, onClose }: MenuItemProps): ReactElement => {
  return (
    <>
      <MenuCollapseItem
        open={open}
        onClick={onClick}
        label={<Trans i18nKey="common.title.ccav" />}
        direction={"horizontal"}
        icon={
          <Icon
            name="ccav"
            size="medium"
          />
        }
      />

      <MenuContractItems
        open={open}
        closeMenu={onClose}
        loadingType={"CCAV"}
        label={"Contrats"}
      />
    </>
  );
};

export default MenuCcav;
