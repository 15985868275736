import { ButtonProps as ButtonPropsMui } from "@mui/material/Button";
import React, { ReactElement } from "react";
import { StyledButton } from "@ui/button/Button.styles";
import theme from "@styles/theme";

export interface ButtonProps
  extends Pick<ButtonPropsMui, "sx" | "onClick" | "disabled" | "variant" | "onMouseEnter" | "onMouseLeave" | "color"> {
  backgroundColor?: string;
  borderColor?: string;
  children: ReactElement | string;
  fullWidth?: boolean;
  textColor?: string;
  isIcon?: boolean;
}

export const Button = ({
  backgroundColor = theme.colors.palette.secondary.main,
  borderColor,
  children,
  disabled,
  fullWidth = false,
  isIcon = false,
  onClick,
  sx,
  textColor = "#FFFFFF",
  variant = "contained",
  ...remaining
}: ButtonProps): ReactElement | null => {
  return (
    <StyledButton
      backgroundcolor={backgroundColor}
      bordercolor={borderColor !== "" ? borderColor : undefined}
      disableRipple
      disabled={disabled}
      fullWidth={fullWidth}
      isicon={isIcon.toString()}
      onClick={onClick}
      sx={sx}
      textcolor={textColor}
      variant={variant}
      {...remaining}
    >
      {children}
    </StyledButton>
  );
};
