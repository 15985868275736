import { Badge, Icon, IconButton, TextField } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";
import styled from "@emotion/styled";

import Tooltip from "@ui/Tooltip";
import DatePickerActionBar from "@components/interventionsBoard/DatePickerActionBar";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  > * {
    margin-left: 10px;
  }
`;

class ActionBar extends React.Component {
  renderUnpublished = (unpublishedCount) => {
    const icon = <Icon color="secondary">tap_and_play</Icon>;

    if (!unpublishedCount) {
      return icon;
    }

    return (
      <Badge
        id="cpbr-unpublished-all"
        badgeContent={unpublishedCount}
        max={1000}
        color="secondary"
      >
        {icon}
      </Badge>
    );
  };

  render() {
    const { changeCurrentDate, currentDate, refreshJobs, unpublishedCount } = this.props;

    return (
      <Wrapper>
        <DatePickerActionBar
          currentDate={currentDate}
          onChange={changeCurrentDate}
        />

        <Tooltip
          title={<Trans i18nKey="refresh" />}
          placement="top"
        >
          <IconButton
            id="cpbr-refresh"
            onClick={refreshJobs}
            size="large"
            color="secondary"
          >
            <Icon>refresh</Icon>
          </IconButton>
        </Tooltip>

        <Tooltip
          title={<Trans i18nKey="dispatch" />}
          placement="top"
        >
          <IconButton
            onClick={this.props.publishJobs}
            size="large"
            color="secondary"
          >
            {this.renderUnpublished(unpublishedCount)}
          </IconButton>
        </Tooltip>
      </Wrapper>
    );
  }
}

ActionBar.propTypes = {
  changeCurrentDate: PropTypes.func.isRequired,
  currentDate: PropTypes.string.isRequired,
  publishJobs: PropTypes.func.isRequired,
  refreshJobs: PropTypes.func.isRequired,
  unpublishedCount: PropTypes.number.isRequired,
};

export default ActionBar;
