// TODO Change .js to .ts

import { useCallback, useMemo } from "react";

import { useAuth } from "@context/auth/AuthProvider";

const SUPER_ADMIN = "SUPER_ADMIN";
const ADMIN = "ADMIN";
const ACCOUNTING = "ACCOUNTING";

/**
 * The callback to set new roles to the current user
 *
 * @callback SetRolesHandler
 * @param {string[]} roles
 * @returns {void}
 */

/**
 * @callback UseCurrentRoles
 * @returns {{
 *    auth: User,
 *    isSuperAdmin: boolean,
 *    isAdmin: boolean,
 *    isAccountant: boolean,
 *    setRoles: (roles: string[]) => void
 *  }}
 */

/**
 * Returns the current user roles + function to set them
 *
 * @type {UseCurrentRoles}}
 */
const useCurrentRoles = () => {
  const { auth, setAuth } = useAuth();

  const isSuperAdmin = useMemo(() => auth.roles.includes(SUPER_ADMIN), [auth.roles]);
  const isAdmin = useMemo(() => auth.roles.includes(ADMIN), [auth.roles]);
  const isAccountant = useMemo(() => auth.roles.includes(ACCOUNTING), [auth.roles]);

  /**
   * @type {SetRolesHandler}
   */
  const setRoles = useCallback(
    (roles) => {
      setAuth((auth) => ({
        ...auth,
        roles,
      }));
    },
    [setAuth]
  );

  return {
    auth,
    isSuperAdmin,
    isAdmin,
    isAccountant,
    setRoles,
  };
};

export default useCurrentRoles;
