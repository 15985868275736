import React, { ReactElement } from "react";
import { Backdrop, Drawer } from "@mui/material";
import { List, Wrapper } from "@components/header/menu/Menu.styles";
import { User } from "@@types/users/user.type";
import MenuItem from "@components/header/menu/MenuItem";
import Icon from "@components/icons/Icon";
import { Trans } from "react-i18next";
import useMenu from "@components/header/menu/hooks/menu.hook";
import MenuCcav from "@components/header/menu/MenuCcav";
import MenuPep from "@components/header/menu/MenuPep";
import MenuAdmin from "@components/header/menu/MenuAdmin";
import MenuInventory from "@components/header/menu/MenuInventory";
import { formattedDate } from "@utils/dates";

export type MenuProps = {
  opened: boolean;
  onClose: () => void;
  user: User;
};

const Menu = ({ opened, onClose, user }: MenuProps): ReactElement => {
  const { collapseRows, handleClickMenuOption, handleCloseNestedMenu } = useMenu(onClose);
  return (
    <>
      <Backdrop
        sx={{ zIndex: 2 }}
        open={opened}
        onClick={handleCloseNestedMenu}
      />

      <Drawer
        sx={{ zIndex: 4, display: "flex", marginRight: 200, position: "inherit" }}
        open={opened}
        onClose={onClose}
        hideBackdrop
      >
        <Wrapper
          tabIndex={0}
          role="button"
        >
          <List>
            {/* DASHBOARD */}
            <MenuItem
              onClick={() => handleClickMenuOption(`/?date=${formattedDate()}`)}
              label={<Trans i18nKey="common.title.assignation" />}
              disabled={false}
              icon={
                <Icon
                  name="dashboard"
                  size="medium"
                />
              }
            />

            {/* CCAV */}
            <MenuCcav
              open={collapseRows.ccav}
              onClick={() => handleClickMenuOption("ccav")}
              onClose={handleCloseNestedMenu}
            />

            {/* LATERAL */}
            <MenuPep
              collapseRows={collapseRows}
              onClick={handleClickMenuOption}
              onClose={handleCloseNestedMenu}
            />

            {/* ROLLOFF */}
            <MenuItem
              onClick={() => handleClickMenuOption("/roll-off-lifting-requests")}
              label={<Trans i18nKey="common.title.roll_off" />}
              disabled={false}
              icon={
                <Icon
                  name="rollOff"
                  size="medium"
                />
              }
            />

            {/* ADMINISTRATION */}
            <MenuAdmin
              onClick={handleClickMenuOption}
              collapseRows={collapseRows}
              disabled={!user.isUserAccounting}
            />

            {/* INVENTAIRE */}
            <MenuInventory
              collapseRows={collapseRows}
              onClick={handleClickMenuOption}
            />
          </List>
        </Wrapper>
      </Drawer>
    </>
  );
};

export default Menu;
