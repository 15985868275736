import { IconProps } from "@components/icons/Icon.type";
import React, { ReactElement } from "react";
import Container from "@components/icons/Container";

export const AdministrationIcon = ({ size, fillColor, strokeColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H12.5M4 13.5V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8M9 2H15C15.5523 2 16 2.44772 16 3V5C16 5.55228 15.5523 6 15 6H9C8.44772 6 8 5.55228 8 5V3C8 2.44772 8.44772 2 9 2ZM13.378 15.626C13.5752 15.4288 13.7317 15.1946 13.8385 14.9369C13.9452 14.6792 14.0001 14.4029 14.0001 14.124C14.0001 13.8451 13.9452 13.5688 13.8385 13.3111C13.7317 13.0534 13.5752 12.8192 13.378 12.622C13.1808 12.4248 12.9466 12.2683 12.6889 12.1615C12.4312 12.0548 12.1549 11.9999 11.876 11.9999C11.5971 11.9999 11.3208 12.0548 11.0631 12.1615C10.8054 12.2683 10.5712 12.4248 10.374 12.622L5.364 17.634C5.12625 17.8716 4.95222 18.1653 4.858 18.488L4.021 21.358C3.9959 21.444 3.9944 21.5353 4.01664 21.6221C4.03889 21.7089 4.08407 21.7882 4.14745 21.8516C4.21083 21.9149 4.29008 21.9601 4.37691 21.9824C4.46374 22.0046 4.55495 22.0031 4.641 21.978L7.511 21.141C7.83367 21.0468 8.12738 20.8728 8.365 20.635L13.378 15.626Z"
          stroke="#2196F3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};
