import { useQuery, UseQueryResult } from "@tanstack/react-query";
import contractRoutes from "@hooks/contract-routes/contract-routes.keys";
import { ContractRouteResponse } from "@@types/contract-routes/contract-route-response.type";
import { ContractRoute } from "@@types/contract-routes/contract-route.type";
import mapResponsesToContractRoutes from "@@types/contract-routes/mappers/mapResponseToContractRoutes.mapper";
import fetchContractRoutesByLoadingType from "../../services/contract-routes/fetch-contract-routes-by-loading-type.service";

const useFetchContractRoutesByLoadingType = (loadingType: string): UseQueryResult<ContractRoute[]> => {
  return useQuery({
    queryKey: contractRoutes.list({ loadingType }),
    queryFn: () => fetchContractRoutesByLoadingType(loadingType),
    select: ({ data: response }): ContractRouteResponse[] => mapResponsesToContractRoutes(response || []),
  });
};

export default useFetchContractRoutesByLoadingType;
