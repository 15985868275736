import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Grid,
  Button,
  TableContainer,
  Paper,
} from "@mui/material";
import styled from "@emotion/styled";
import { debounce, orderBy } from "lodash";
import { PropTypes } from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import { withRouteTemplates, withRouteTemplate, withCiWebContractRoutes } from "optigo-redux";

import ModalWarning from "./ModalWarning";
import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import TableCellNoData from "./ui/TableCellNoData";
import TableLoading from "./ui/TableLoading";
import TablePaginationWrapper from "./ui/TablePaginationWrapper";
import TableOverflowWrapper from "./ui/TableOverflowWrapper";
import TextFieldUi from "./ui/TextField";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { filteringState, handleFilter, handlePageChange, handleRowsPerPageChange, handleSort } from "@utils/filtering";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { withRouter } from "@utils/withRouter";
import ModalCustomerLocation from "./ModalCustomerLocation";
import ModalAddSubRouteTemplate from "@components/subRouteManagement/master-route-template-menu/modal-add-sub-route-template/ModalAddSubRouteTemplate";
import ModalAddMasterRouteTemplate from "@components/modals/routeTemplate/add-master-route-template/ModalAddMasterRouteTemplate";
import CollapsableListParent from "@ui/collapsable-list/CollapsableListParent";
import {
  CollectionPath,
  MasterCollectionSectorsPath,
  PEP_ROUTES,
  ROUTES_PARAMETERS,
} from "@components/routing/constants";

const headerLabels = {
  name: { label: <Trans i18nKey="route_templates.name" /> },
  masterRoute: { label: <Trans i18nKey="route_templates.master_route_name" /> },
  routeLocationsCount: { label: <Trans i18nKey="route_templates.lifting_count" /> },
  createRouteTemplateCI: { label: <Trans i18nKey="route_templates.sub_route" /> },
  collapse: { label: <Trans i18nKey="expand" /> },
  editRoute: { label: <Trans i18nKey="edit_route_template" /> },
  deleteRoute: { label: <Trans i18nKey="route_templates.delete" /> },
};

const CollapsibleRow = styled(TableRow)`
  height: 0 !important;
  background-color: #f8f8f8 !important;

  td {
    display: none !important;
    height: 0 !important;
    overflow: hidden;
    min-height: 0px;
  }

  &.expended-true {
    height: initial;

    td {
      display: table-cell !important;
    }
  }
`;

const CustomTable = styled(Table)`
  tbody {
    tr:nth-of-type(even) {
      background-color: transparent;
    }

    tr.collapsible-row {
      background-color: #f7f7f7;
    }
  }
`;

class RouteTemplatesList extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  initialSort = {
    columnName: "name",
    direction: "asc",
  };

  // eslint-disable-next-line react/sort-comp
  initialState = {
    ...filteringState,
    deleteRouteTemplateModalOpened: false,
    ciWebContext: false,
    masterRouteIdForDelete: null,
    routeTemplateModalOpened: false,
    routeTemplateToEdit: {},
    masterRouteIdForUpdate: null,
    openCreateRouteTemplate: false,
    openCreateMasterRouteTemplate: false,
    supplier: { id: null, error: false },
    routeTemplates: [],
    newRouteTemplate: { name: "", error: false },
    masterRouteTemplate: {},
    day: { value: null, error: false },
    sort: {
      ...this.initialSort,
    },
    collapseOpen: false,
    collapse: {},
    customerLocationModalOpened: false,
  };

  state = {
    ...this.initialState,
  };
  handleSort = handleSort.bind(this);
  handleFilter = handleFilter(this.debouncedFetchRouteTemplates).bind(this);

  async componentDidMount() {
    await this.props.flushRouteTemplates();
    this.fetchRouteTemplates();
    this.setCiWebContext();
  }

  // Maybe this shoul be an utils / shared tool ?
  setCiWebContext() {
    const { router, ciWebContractRoutes } = this.props;
    const { customerItemId } = router.params;
    const ciWebContractRoute = ciWebContractRoutes.find(
      (item) => parseInt(item.customerItemId) === parseInt(customerItemId)
    );
    if (ciWebContractRoute && ciWebContractRoute.ciWebSync === true) {
      this.setState({ ciWebContext: true });
    }
  }

  componentWillUnmount() {
    this.props.flushRouteTemplates();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
      this.fetchRouteTemplates();
    }

    if (prevProps.routeTemplates !== this.props.routeTemplates) {
      this.createStateForRouteMaster();
    }
  }

  createStateForRouteMaster = () => {
    const tempoObject = {};
    if (this.props.routeTemplates.length === 0 || this.props.routeTemplatesLoading) {
      return null;
    }
    this.props.routeTemplates.forEach((routeTemplate) => {
      if (routeTemplate.isAMasterRoute) {
        const { completeName } = routeTemplate;
        tempoObject[completeName] = false;
      }
    });
    this.setState({ collapse: tempoObject });
  };

  fetchAndResetPagination = () => {
    this.setState(
      {
        ...this.initialState,
      },
      this.fetchRouteTemplates
    );
  };

  fetchRouteTemplates = () => {
    const { fetchRouteTemplatesByCustomerItem, router } = this.props;
    const { filter, limit, page } = this.state;

    fetchRouteTemplatesByCustomerItem(router.params.customerItemId, {
      filter: filter.trim(),
      page: page + 1,
      limit: null,
    });
  };

  debouncedFetchRouteTemplates = debounce(() => {
    this.fetchRouteTemplates();
  }, 300);
  handlePageChange = handlePageChange(this.fetchRouteTemplates).bind(this);
  handleRowsPerPageChange = handleRowsPerPageChange(this.fetchRouteTemplates).bind(this);

  handleAddNewRouteTemplate = () => {
    this.setState({ openCreateMasterRouteTemplate: true });
  };

  handleCloseDeleteRouteTemplateModal = () => {
    this.setState({
      deleteRouteTemplateModalOpened: false,
      routeTemplateToDelete: null,
    });
  };

  handleOpenDeleteRouteTemplateModal = (e, routeTemplateToDeleteId, masterRouteTemplateId) => {
    e.stopPropagation();

    this.setState({
      deleteRouteTemplateModalOpened: true,
      routeTemplateToDelete: routeTemplateToDeleteId,
      masterRouteIdForUpdate: masterRouteTemplateId,
    });
  };

  handleChangeSupplier = (event) => {
    this.setState({
      supplier: { id: event.target.value, error: false },
    });
  };

  handleChangeDay = (event) => {
    this.setState({
      day: { value: event.target.value, error: false },
    });
  };

  handleCloseCreateMasterRouteTemplateModal = () => {
    this.setState({
      openCreateMasterRouteTemplate: false,
      newRouteTemplate: { name: "", error: false },
      supplier: { id: null, error: false },
      day: { value: "", error: false },
    });
  };

  handleCreateNewMasterRouteTemplate = async () => {
    const { day, supplier } = this.state;
    const { createRouteTemplate, router, customerItem } = this.props;
    const { area, loadingType, matter } = customerItem;

    const isValid = this.validNewMasterRouteTemplate();

    if (isValid) {
      await createRouteTemplate(router.params.customerItemId, {
        average_lifting_time: null,
        customer_item_id: customerItem.id,
        master_route: `${loadingType}-${area.toUpperCase()}-${matter}-${day.value}`,
        master_route_id: null,
        is_a_master_route: true,
        name: "-",
        supplier_location_id: supplier.id,
      });

      this.setState({ openCreateMasterRouteTemplate: false, newRouteTemplate: { name: "", error: false } });
      this.fetchRouteTemplates();
    }
  };

  validNewMasterRouteTemplate = () => {
    const { supplier, collapse, day } = this.state;
    const existingMasterRouteTemplatesDays = Object.keys(collapse)
      .map((masterRouteTemplate) => masterRouteTemplate.split("-")[3])
      .join("");

    let isValid = true;

    if (!supplier.id) {
      this.setState({ supplier: { ...supplier, error: true } });
      isValid = false;
    }

    if (existingMasterRouteTemplatesDays.match(day.value) || !day.value) {
      this.setState({ day: { ...day, error: true } });
      isValid = false;
    }

    return isValid;
  };

  handleOpenCreateRouteTemplateModal = (e, id) => {
    const { routeTemplates } = this.props;

    const routeTemplate = routeTemplates.find((routeTemplate) => routeTemplate.id === id);
    e.stopPropagation();
    this.setState({
      openCreateRouteTemplate: true,
      routeTemplates: routeTemplate.children,
      masterRouteTemplate: routeTemplate,
    });
  };

  handleCloseCreateRouteTemplateModal = () => {
    this.setState({
      openCreateRouteTemplate: false,
      routeTemplates: [],
      masterRouteId: "",
      newRouteTemplate: { name: "", error: false },
    });
  };
  handleChangeNewRouteTemplateName = (event) => {
    this.setState({ newRouteTemplate: { name: event.target.value, error: false } });
  };

  handleCreateNewRouteTemplate = async () => {
    const { routeTemplates, newRouteTemplate, masterRouteTemplate, openCreateRouteTemplate } = this.state;
    const { createRouteTemplate, router } = this.props;

    const isValid = this.valid(newRouteTemplate, routeTemplates);

    if (isValid) {
      await createRouteTemplate(router.params.customerItemId, {
        average_lifting_time: masterRouteTemplate.averageLiftingTime,
        customer_item_id: masterRouteTemplate.customerItemId,
        master_route: masterRouteTemplate.masterRoute,
        master_route_id: masterRouteTemplate.id,
        is_a_master_route: false,
        name: newRouteTemplate.name,
        supplier_location_id: masterRouteTemplate.supplierLocationId,
      });

      this.setState({ openCreateRouteTemplate: false, newRouteTemplate: { name: "", error: false } });
      this.fetchRouteTemplates();
    }
  };

  valid = (newRouteTemplate, routeTemplates) => {
    const existingRouteTemplatesNames = routeTemplates.map((routeTemplate) => routeTemplate.name).join("");

    const validNameValues = new RegExp(/^[0-9\b]+$/);

    if (!validNameValues.test(newRouteTemplate.name) || existingRouteTemplatesNames.match(newRouteTemplate.name)) {
      this.setState({ newRouteTemplate: { ...newRouteTemplate, error: true } });
      return false;
    }
    return true;
  };

  handleDeleteRouteTemplate = async () => {
    const { deleteRouteTemplate } = this.props;
    const { masterRouteIdForUpdate } = this.state;

    await deleteRouteTemplate(this.state.routeTemplateToDelete, masterRouteIdForUpdate);
    const { deleteRouteTemplateModalOpened, routeTemplateToDelete } = this.initialState;

    this.setState(
      {
        deleteRouteTemplateModalOpened,
        routeTemplateToDelete,
      },
      this.fetchRouteTemplates
    );
  };

  renderDeleteIcon = (id, masterRouteId) => (
    <TableCell classes={{ root: "action-cell" }}>
      <IconButton
        color="secondary"
        id="cpbr-delete-route-template"
        onClick={this.handleToggleDeleteRouteTemplateModal(true, id, masterRouteId)}
        size="large"
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </TableCell>
  );

  renderCreateRouteTemplateIcon = (routeTemplates, masterRouteTemplate) => (
    <TableCell classes={{ root: "action-cell" }}>
      <IconButton
        color="secondary"
        id="cpbr-add-create-route-template"
        onClick={this.handleOpenCreateRouteTemplateModal(routeTemplates, masterRouteTemplate)}
        size="large"
      >
        <AddIcon fontSize="small" />
      </IconButton>
    </TableCell>
  );

  renderTableHead = () => {
    const { columnName, direction } = this.state.sort;

    return (
      <TableHead>
        <TableRow>
          {["collapse", "name", "masterRoute", "routeLocationsCount", "createRouteTemplateCI"].map((name) => (
            <TableCell key={name}>
              <TableSortLabel
                active={columnName === name}
                direction={direction}
                onClick={this.handleSort(name)}
              >
                {headerLabels[name].label}
              </TableSortLabel>
            </TableCell>
          ))}

          <TableCell />
        </TableRow>
      </TableHead>
    );
  };

  handleCollapse = (routeMasterName) => {
    const { collapse, collapseOpen } = this.state;
    const copyCollapse = collapse;
    const actualValue = copyCollapse[routeMasterName];
    copyCollapse[routeMasterName] = !actualValue;
    this.setState({ collapse: copyCollapse, collapseOpen: !collapseOpen });
  };

  renderTableRowRouteTemplate = (routeTemplateMaster, arrayWithOutRouteMaster, collapse, completeName) => {
    const arrayWithOut = [];
    let rowToReturn = null;
    const { router } = this.props;
    const { customerId, contractId } = router.params;

    const getRowPath = ({ customerId, customerItemId, masterRouteId, id }) => {
      if (import.meta.env.VITE_FEATURE_FLAG_SUB_ROUTE_V2 === "true") {
        return `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/route_template_locations?masterRouteId=${masterRouteId}&routeTemplateIds=${id}`;
      }

      return `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/route_templates/${id}/edit/master_route/${masterRouteId}`;
    };

    arrayWithOutRouteMaster.map((routeTemplate) => {
      if (routeTemplate.masterRoute === routeTemplateMaster.masterRoute) {
        arrayWithOut.push(routeTemplate);
      }
    });

    rowToReturn = this.ordreRouteTemplate(arrayWithOut, "name").map((routeTemplate) => {
      const { id, customerItemId, name, masterRoute, routeLocationsCount, masterRouteId } = routeTemplate;
      return (
        <CollapsibleRow
          key={id}
          className={`collapsible-row expended-${collapse[completeName]} link-row`}
          onClick={this.handleRowClick(getRowPath({ masterRouteId, customerId, customerItemId, id }))}
        >
          <TableCell />
          <TableCell>{name}</TableCell>
          <TableCell>{masterRoute}</TableCell>
          <TableCell>{routeLocationsCount}</TableCell>
          <TableCell />
          <TableCell />
          {this.renderDeleteIcon(id, masterRouteId)}
        </CollapsibleRow>
      );
    });
    return rowToReturn;
  };

  ordreRouteTemplate = (arrayRouteMaster, key) => {
    return arrayRouteMaster.sort((a, b) => a[key] - b[key]);
  };

  renderTableMasterRouteRows = () => {
    const { collapse } = this.state;
    const { routeTemplates, routeTemplatesLoading, router } = this.props;
    const customerId = router.params.customerId;
    const contractId = router.params.contractId;
    const customerItemId = router.params.customerItemId;
    const arrayRouteMaster = [];
    let arrayWithOutRouteMaster = [];
    let rowToReturn = null;

    if (routeTemplatesLoading) {
      return <TableLoading />;
    }

    if (!routeTemplates.length) {
      return <TableCellNoData />;
    }

    routeTemplates.forEach((routeTemplate) => {
      if (routeTemplate.isAMasterRoute || routeTemplate.masterRouteId === null) {
        arrayRouteMaster.push(routeTemplate);
      }
    });

    arrayWithOutRouteMaster = routeTemplates.filter((ar) => !arrayRouteMaster.find((rm) => rm.id === ar.id)); // les sous-routes des route-maîtres
    const subRouteOnly = arrayWithOutRouteMaster.filter((awrm) => awrm.masterRouteId !== null);

    rowToReturn = this.ordreRouteTemplate(arrayRouteMaster, "sequence").map((routeTemplateMaster, index) => {
      const { id, masterRoute, name, routeLocationsCount, masterRouteId, isAMasterRoute, completeName } =
        routeTemplateMaster;

      const getRowPath = ({ customerId, customerItemId, id }) => {
        if (import.meta.env.VITE_FEATURE_FLAG_SUB_ROUTE_V2 === "true") {
          return `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/route_template_locations?masterRouteId=${id}&routeTemplateIds=${id}`;
        }

        return `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/route_templates/${id}/edit`;
      };

      const subRoutes = subRouteOnly.filter((subRoute) => subRoute.masterRouteId === id);
      const masterRouteTemplate = routeTemplates.find((routeTemplate) => routeTemplate.id === id);

      return (
        <React.Fragment key={index}>
          <TableRow
            className="link-row"
            key={id}
            onClick={() => this.handleCollapse(completeName)}
          >
            <TableCell>
              <IconButton
                size="large"
                color="secondary"
              >
                {collapse[completeName] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{masterRoute}</TableCell>
            <TableCell>{routeLocationsCount}</TableCell>
            {isAMasterRoute && masterRouteId === null ? (
              this.renderCreateRouteTemplateIcon(subRoutes, masterRouteTemplate)
            ) : (
              <TableCell />
            )}

            <TableCell>
              <IconButton
                color="secondary"
                onClick={this.handleRowClick(getRowPath({ customerItemId, customerId, id }))}
                size="large"
              >
                <OpenInNewIcon />
              </IconButton>
            </TableCell>
            <TableCell>-</TableCell>
          </TableRow>

          {this.renderTableRowRouteTemplate(routeTemplateMaster, subRouteOnly, collapse, completeName)}
        </React.Fragment>
      );
    });
    return rowToReturn;
  };

  handleToggleCustomerLocationModal = (action) => {
    this.setState({
      customerLocationModalOpened: action,
    });
  };

  handleRowClick = (path) => () => {
    this.props.router.navigate(path);
  };

  render() {
    const { routeTemplates, routeTemplatesCount, router, customerItem } = this.props;
    const customerId = router.params.customerId;
    const contractId = router.params.contractId;
    const customerItemId = router.params.customerItemId;

    const {
      filter,
      limit,
      page,
      deleteRouteTemplateModalOpened,
      customerLocationModalOpened,
      ciWebContext,
      openCreateRouteTemplate,
      openCreateMasterRouteTemplate,
      newRouteTemplate,
      supplier,
      day,
    } = this.state;

    return (
      <PageContainer>
        <PaperWrapper>
          <TableOverflowWrapper>
            <Toolbar>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <TextFieldUi
                    id="cpbr-filtre"
                    label={<Trans i18nKey="filter" />}
                    onChange={this.handleFilter}
                    type="search"
                    value={filter}
                  />
                </Grid>

                {!ciWebContext && customerItem?.contractOperationalFlow === "CCAV" ? (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleAddNewRouteTemplate}
                      style={{ marginRight: 8 }}
                    >
                      Ajouter un modèle de route
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => this.handleToggleCustomerLocationModal(true)}
                    >
                      Ajouter un emplacement
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Toolbar>

            <CustomTable>
              {this.renderTableHead()}
              <TableBody>
                {routeTemplates
                  .sort((a, b) => a.sequence - b.sequence)
                  .map((template) => {
                    const path = template?.geoCollectionSectorUuid
                      ? MasterCollectionSectorsPath({
                          customerItemId,
                          collectionSectorIds: [template.geoCollectionSectorUuid],
                          masterCollectionSectorId: template.geoCollectionSectorUuid,
                          geoCollectionUUID: template.geoCollectionSectorUuid,
                        })
                      : `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/route_template_locations?masterRouteId=${template.id}&routeTemplateIds=${template.id}`;
                    return (
                      <CollapsableListParent
                        id={template.id}
                        masterRoute={template.masterRoute}
                        geoCollectionSectorUuid={template.geoCollectionSectorUuid}
                        name={template.name}
                        children={template.children}
                        routeTemplateLocationsCount={template?.routeTemplateLocationsCount}
                        path={path}
                        onCreate={this.handleOpenCreateRouteTemplateModal}
                        onDelete={this.handleOpenDeleteRouteTemplateModal}
                        templateType={customerItem?.contractOperationalFlow}
                      />
                    );
                  })}
              </TableBody>
            </CustomTable>
          </TableOverflowWrapper>

          <TablePaginationWrapper
            component="div"
            count={routeTemplatesCount}
            id="cpbr-pagination"
            labelRowsPerPage=""
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleRowsPerPageChange}
            page={page}
            rowsPerPage={limit}
          />

          {customerLocationModalOpened && (
            <ModalCustomerLocation
              // key={false}
              actionName={<Trans i18nKey="add" />}
              customerId={router.params.customerId}
              contractId={router.params.contractId}
              callback={this.fetchAndResetPagination}
              customerLocation={{}}
              onClose={() => this.handleToggleCustomerLocationModal(false)}
              open={customerLocationModalOpened}
              refreshList={this.fetchAndResetPagination}
            />
          )}

          {openCreateRouteTemplate && (
            <ModalAddSubRouteTemplate
              newSubRouteTemplate={newRouteTemplate}
              opened={openCreateRouteTemplate}
              onClose={this.handleCloseCreateRouteTemplateModal}
              onChange={this.handleChangeNewRouteTemplateName}
              onCreate={this.handleCreateNewRouteTemplate}
            />
          )}

          {openCreateMasterRouteTemplate && (
            <ModalAddMasterRouteTemplate
              day={day}
              type={this.props.customerItem.loadingType}
              area={this.props.customerItem.area}
              matter={this.props.customerItem.matter}
              supplier={supplier}
              opened={openCreateMasterRouteTemplate}
              onClose={this.handleCloseCreateMasterRouteTemplateModal}
              onCreate={this.handleCreateNewMasterRouteTemplate}
              onSupplierChange={this.handleChangeSupplier}
              onDayChange={this.handleChangeDay}
            />
          )}

          <ModalWarning
            onCancel={this.handleCloseDeleteRouteTemplateModal}
            onSubmit={this.handleDeleteRouteTemplate}
            open={deleteRouteTemplateModalOpened}
            title={<Trans i18nKey="warning" />}
          >
            <Trans i18nKey="warning_delete_route_template" />
          </ModalWarning>
        </PaperWrapper>
      </PageContainer>
    );
  }
}

RouteTemplatesList.defaultProps = {
  routeTemplatesLoading: true,
};

RouteTemplatesList.propTypes = {
  customerItem: PropTypes.object.isRequired,
  deleteRouteTemplate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  fetchRouteTemplatesByCustomerItem: PropTypes.func.isRequired,
  flushRouteTemplates: PropTypes.func.isRequired,

  routeTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  routeTemplatesCount: PropTypes.number.isRequired,
  routeTemplatesLoading: PropTypes.bool,
};

export default withCiWebContractRoutes(withRouter(withRouteTemplates(withRouteTemplate(RouteTemplatesList))));
