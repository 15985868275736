import { axiosClientV2 } from "../axios";

const responseType = "blob";

const createMasterRoutesReportXlsx = async (customerId: string): Promise<string> => {
  try {
    const res = await axiosClientV2.post<string>(
      `/reports/master_routes_report_to_xlsx`,
      { customerId },
      { responseType }
    );
    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default createMasterRoutesReportXlsx;
