import React, { ReactElement } from "react";
import { ListItemIcon, ListItemText } from "@mui/material";
import { ListItemButton } from "@components/header/menu/Menu.styles";

type MenuItemProps = {
  onClick: () => void;
  label: string | ReactElement;
  disabled: boolean;
  icon?: ReactElement;
};

const MenuItem = ({ label, onClick, disabled, icon }: MenuItemProps): ReactElement => {
  return (
    <ListItemButton
      sx={{ background: icon ? "" : "#FAFAFA" }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>{icon}</ListItemIcon>}
      <ListItemText
        sx={{ marginLeft: icon ? "" : "57px" }}
        primary={label}
      />
    </ListItemButton>
  );
};

export default MenuItem;
