import React, { ReactElement } from "react";
import MenuCollapseItem from "@components/header/menu/MenuCollapseItem";
import { Trans } from "react-i18next";
import Icon from "@components/icons/Icon";
import MenuContractItems from "@components/header/menu/MenuContractItems";
import MenuAdminSectorItems from "@components/header/menu/MenuAdminSectorItems";
import { CollapseRows } from "@components/report/financial-report-table/FinancialReportTableBody";

type MenuItemProps = {
  onClick: (value: string) => void;
  onClose: () => void;
  collapseRows: CollapseRows;
};

const MenuPep = ({ collapseRows, onClick, onClose }: MenuItemProps): ReactElement => {
  return (
    <MenuCollapseItem
      open={collapseRows.pep}
      onClick={() => onClick("pep")}
      label={<Trans i18nKey="common.title.lateral" />}
      direction={"vertical"}
      icon={
        <Icon
          name="pep"
          size="medium"
        />
      }
    >
      {/* CONTRATS */}
      <>
        <MenuCollapseItem
          open={collapseRows.sectors}
          onClick={() => onClick("sectors")}
          label={<Trans i18nKey="contracts" />}
          direction={"horizontal"}
          isChildren={true}
        />
        <MenuContractItems
          open={collapseRows.sectors}
          closeMenu={onClose}
          loadingType={"PEP"}
          label={"Contrats"}
        />
      </>

      {/* SECTEURS ADMINISTRATIF */}
      {/*<>*/}
      {/*  <MenuCollapseItem*/}
      {/*    open={collapseRows.adminSectors}*/}
      {/*    onClick={() => onClick("adminSectors")}*/}
      {/*    label={<Trans i18nKey="common.title.admin_sectors" />}*/}
      {/*    direction={"horizontal"}*/}
      {/*    isChildren={true}*/}
      {/*  />*/}
      {/*  <MenuAdminSectorItems*/}
      {/*    open={collapseRows.adminSectors}*/}
      {/*    closeMenu={onClose}*/}
      {/*  />*/}
      {/*</>*/}
    </MenuCollapseItem>
  );
};

export default MenuPep;
