import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const trHeight = "60px;";

const placeHolderShimmer = keyframes`
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
`;

const RowOdd = styled.tr`
  td {
    height: ${trHeight};
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: #f6f6f6;
    background: linear-gradient(to right, #f7f7f7 8%, #f3f3f3 18%, #f7f7f7 33%);
    background-size: 1200px 104px;
    border-top: solid 1px #e0e0e0;
  }
`;

const RowEven = styled.tr`
  td {
    height: ${trHeight};
    border-top: solid 1px #e0e0e0;
  }
`;

const createRange = (from, to) => {
  let range = [];
  for (let i = from; i <= to; i++) {
    range.push(i);
  }
  return range;
};

const TableLoading = ({ skeletonSize = 5 }) =>
  createRange(1, skeletonSize).map((index) => (
    <Fragment key={index}>
      <RowEven>
        <td
          height="200"
          colSpan="99"
        />
      </RowEven>

      <RowOdd>
        <td
          height="200"
          colSpan="99"
        />
      </RowOdd>
    </Fragment>
  ));

export default TableLoading;
