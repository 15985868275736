import { ContractRoute } from "@@types/contract-routes/contract-route.type";
import { ContractRouteResponse } from "@@types/contract-routes/contract-route-response.type";

function mapResponseToContractRoutes(response: ContractRouteResponse): ContractRoute {
  return {
    id: response.id,
    routeName: response.routeName,
    customerItemId: response.customerItemId,
    contractId: response.contractId,
    customerId: response.customerId,
    matterCode: response.matterCode,
    district: response.district,
    districtCompleteName: response.districtCompleteName,
  };
}

function mapResponsesToContractRoutes(responses: ContractRouteResponse[]): ContractRoute[] {
  return responses.map(mapResponseToContractRoutes);
}

export default mapResponsesToContractRoutes;
