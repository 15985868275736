import React from "react";
import { Outlet } from "react-router-dom";
import { withFeatureFlagsContext } from "@context/featureFlags/FeatureFlagsContext";

import Header from "@components/header/Header";
import SentryErrorBoundary from "@components/routing/SentryErrorBoundary";
import { withAdministrativeSectorsContext } from "@context/geo/AdministrativeSectorsContext";
import { withListCodesContext } from "@context/list-codes/ListCodesContext";

function Layout() {
  return (
    <>
      <Header />

      <main>
        <SentryErrorBoundary>
          <Outlet />
        </SentryErrorBoundary>
      </main>
    </>
  );
}

export default withFeatureFlagsContext(withAdministrativeSectorsContext(withListCodesContext(Layout)));
