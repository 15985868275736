import React, { ReactElement } from "react";
import { List, ListItemButton, Wrapper } from "@components/header/menu/Menu.styles";
import { Collapse, ListItemIcon, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

export type MenuCollapseItemProps = {
  open: boolean;
  onClick: () => void;
  label: string | ReactElement;
  children?: ReactElement | ReactElement[];
  direction: "vertical" | "horizontal";
  icon?: ReactElement;
  isChildren?: boolean;
};

const MenuCollapseItem = ({
  open,
  onClick,
  label,
  direction,
  children,
  icon,
  isChildren,
}: MenuCollapseItemProps): ReactElement => {
  let arrows;

  if (direction === "vertical") {
    arrows = open ? <ExpandLess /> : <ExpandMore />;
  } else {
    arrows = <KeyboardArrowRightOutlinedIcon />;
  }

  return (
    <Wrapper>
      <List>
        <ListItemButton
          sx={{
            background: open ? (direction === "horizontal" ? "#F5FAFE" : "") : isChildren ? "#FAFAFA" : "",
          }}
          onClick={onClick}
        >
          {icon && <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>{icon}</ListItemIcon>}
          <ListItemText
            sx={{ marginLeft: icon ? "" : "57px" }}
            primary={label}
          />
          {arrows}
        </ListItemButton>

        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
        >
          <List
            disablePadding
            style={{
              overflow: "auto",
              maxHeight: 300,
            }}
          >
            {children}
          </List>
        </Collapse>
      </List>
    </Wrapper>
  );
};

export default MenuCollapseItem;
