import { QueryKey } from "@tanstack/react-query";

const contractRoutes = {
  all: (): QueryKey => ["contract-routes"],
  lists: (): QueryKey => [...contractRoutes.all(), "list"],
  list: (filters: object): QueryKey => [...contractRoutes.lists(), { filters }],
  details: (): QueryKey => [...contractRoutes.all(), "detail"],
  detail: (id: number): QueryKey => [...contractRoutes.details(), id.toString()],
};

export default contractRoutes;
