import React, { ReactElement } from "react";
import { Drawer } from "@mui/material";
import { WrapperChild } from "@components/header/menu/Menu.styles";

type MenuTransactionsProps = {
  openDrawerExtension: boolean;
  onClose: () => void;
  children: ReactElement | ReactElement[];
};

const MenuDrawerExtension = ({ openDrawerExtension, onClose, children }: MenuTransactionsProps): ReactElement => {
  return (
    <Drawer
      sx={{ zIndex: 3 }}
      open={openDrawerExtension}
      onClose={onClose}
      hideBackdrop
    >
      <WrapperChild
        onClick={onClose}
        onKeyDown={onClose}
      >
        {children}
      </WrapperChild>
    </Drawer>
  );
};

export default MenuDrawerExtension;
