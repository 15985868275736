import { ApiResponse } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import { ContractRoute } from "@@types/contract-routes/contract-route.type";

const fetchContractRoutesByLoadingType = async (loadingType: string): Promise<ApiResponse<ContractRoute[]>> => {
  try {
    const res = await http.get<ApiResponse<ContractRoute[]>>(
      `/contract_routes/by_loading_type?loading_type=${loadingType}`
    );

    return res.data || [];
  } catch (error) {
    throw error as Error;
  }
};

export default fetchContractRoutesByLoadingType;
