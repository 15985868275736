import { IconProps } from "@components/icons/Icon.type";
import React, { ReactElement } from "react";
import Container from "@components/icons/Container";

export const PepIcon = ({ size, fillColor, strokeColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
          stroke="#2196F3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 13.7143V15H10.0844V13.7143H14ZM10.6026 7V15H9V7H10.6026Z"
          fill="#2196F3"
        />
      </svg>
    </Container>
  );
};
