import styled from "@emotion/styled";
import { List as MuiList, ListItemButton as MuiListItemButton } from "@mui/material";

export const Wrapper = styled.div`
  width: 300px;
`;

export const WrapperChild = styled.div`
  width: 510px;
`;

export const List = styled(MuiList)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
`;

export const ListItemButton = styled(MuiListItemButton)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    gap: 25px;

    svg {
      margin-right: 0;
      margin-left: 10px;
    }
  }
`;
