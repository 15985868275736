import { IconProps } from "@components/icons/Icon.type";
import React, { ReactElement } from "react";
import Container from "@components/icons/Container";

export const CcavIcon = ({ size, fillColor, strokeColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
          stroke="#2196F3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7695 12.3389H14.3755C14.3433 12.8652 14.1982 13.3325 13.9404 13.7407C13.6862 14.1489 13.3299 14.4676 12.8716 14.6968C12.4168 14.9259 11.869 15.0405 11.228 15.0405C10.7267 15.0405 10.2773 14.9546 9.87988 14.7827C9.48242 14.6073 9.14225 14.3566 8.85938 14.0308C8.58008 13.7049 8.36702 13.311 8.22021 12.8491C8.0734 12.3872 8 11.8698 8 11.2969V10.7544C8 10.1815 8.0752 9.66406 8.22559 9.20215C8.37956 8.73665 8.59798 8.34098 8.88086 8.01514C9.16732 7.68929 9.50928 7.43864 9.90674 7.26318C10.3042 7.08773 10.7482 7 11.2388 7C11.8905 7 12.4401 7.11816 12.8877 7.35449C13.3389 7.59082 13.688 7.91667 13.9351 8.33203C14.1857 8.7474 14.3361 9.22005 14.3862 9.75H12.7749C12.757 9.4349 12.6943 9.16813 12.5869 8.94971C12.4795 8.7277 12.3166 8.5612 12.0981 8.4502C11.8833 8.33561 11.5968 8.27832 11.2388 8.27832C10.9702 8.27832 10.7357 8.32845 10.5352 8.42871C10.3346 8.52897 10.1663 8.68115 10.0303 8.88525C9.89421 9.08936 9.79215 9.34717 9.72412 9.65869C9.65967 9.96663 9.62744 10.3283 9.62744 10.7437V11.2969C9.62744 11.7015 9.65788 12.0578 9.71875 12.3657C9.77962 12.6701 9.87272 12.9279 9.99805 13.1392C10.127 13.3468 10.2917 13.5044 10.4922 13.6118C10.6963 13.7157 10.9416 13.7676 11.228 13.7676C11.5646 13.7676 11.8421 13.7139 12.0605 13.6064C12.279 13.499 12.4455 13.3397 12.5601 13.1284C12.6782 12.9172 12.748 12.654 12.7695 12.3389Z"
          fill="#2196F3"
        />
      </svg>
    </Container>
  );
};
