import React, { ReactElement } from "react";
import MenuCollapseItem from "@components/header/menu/MenuCollapseItem";
import { Trans } from "react-i18next";
import Icon from "@components/icons/Icon";
import { CollapseRows } from "@components/report/financial-report-table/FinancialReportTableBody";
import MenuItem from "@components/header/menu/MenuItem";

type MenuItemProps = {
  onClick: (value: string) => void;
  collapseRows: CollapseRows;
};

const MenuInventory = ({ collapseRows, onClick }: MenuItemProps): ReactElement => {
  return (
    <MenuCollapseItem
      open={collapseRows.inventory}
      onClick={() => onClick("inventory")}
      icon={
        <Icon
          name="inventory"
          size="medium"
        />
      }
      label={<Trans i18nKey="common.title.inventory" />}
      direction={"vertical"}
    >
      {/* VEHICULES */}
      <MenuItem
        onClick={() => onClick("/units")}
        label={<Trans i18nKey="common.title.vehicle" />}
        disabled={false}
      />

      {/* CONTENEURS */}
      <MenuItem
        onClick={() => onClick("/items")}
        label={<Trans i18nKey="common.title.containers" />}
        disabled={false}
      />
    </MenuCollapseItem>
  );
};

export default MenuInventory;
