import { IconProps } from "@components/icons/Icon.type";
import React, { ReactElement } from "react";
import Container from "@components/icons/Container";

export const RollOffIcon = ({ size, fillColor, strokeColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
          stroke="#2196F3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 7H10.9165C11.5145 7 12.0283 7.08952 12.458 7.26855C12.8913 7.44759 13.2243 7.71257 13.457 8.06348C13.6898 8.41439 13.8062 8.84587 13.8062 9.35791C13.8062 9.77686 13.7345 10.1367 13.5913 10.4375C13.4517 10.7347 13.2529 10.9836 12.9951 11.1841C12.7409 11.381 12.4419 11.5386 12.0981 11.6567L11.5879 11.9253H9.05273L9.04199 10.6685H10.9272C11.2101 10.6685 11.4447 10.6183 11.6309 10.5181C11.8171 10.4178 11.9567 10.2782 12.0498 10.0991C12.1465 9.92008 12.1948 9.7124 12.1948 9.47607C12.1948 9.22542 12.1483 9.00879 12.0552 8.82617C11.9621 8.64355 11.8206 8.50391 11.6309 8.40723C11.4411 8.31055 11.203 8.26221 10.9165 8.26221H9.61133V14.8203H8V7ZM12.3721 14.8203L10.5889 11.3345L12.2915 11.3237L14.0962 14.7451V14.8203H12.3721Z"
          fill="#2196F3"
        />
      </svg>
    </Container>
  );
};
