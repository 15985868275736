import React, { ReactElement } from "react";
import MenuCollapseItem from "@components/header/menu/MenuCollapseItem";
import { Trans } from "react-i18next";
import Icon from "@components/icons/Icon";
import { CollapseRows } from "@components/report/financial-report-table/FinancialReportTableBody";
import MenuItem from "@components/header/menu/MenuItem";

type MenuItemProps = {
  onClick: (value: string) => void;
  disabled: boolean;
  collapseRows: CollapseRows;
};

const MenuAdmin = ({ collapseRows, onClick, disabled }: MenuItemProps): ReactElement => {
  return (
    <MenuCollapseItem
      open={collapseRows.admin}
      onClick={() => onClick("admin")}
      icon={
        <Icon
          name="administration"
          size="medium"
        />
      }
      label={<Trans i18nKey="common.title.admin" />}
      direction={"vertical"}
    >
      {/* CLIENTS */}
      <MenuItem
        onClick={() => onClick("/customers")}
        label={<Trans i18nKey="common.title.client" />}
        disabled={false}
      />

      {/* PRIX */}
      <MenuItem
        onClick={() => onClick("/prices")}
        label={<Trans i18nKey="common.title.prices" />}
        disabled={disabled}
      />

      {/*TRANSACTIONS */}
      <MenuItem
        onClick={() => onClick("/transactions")}
        label={<Trans i18nKey="common.title.transactions" />}
        disabled={disabled}
      />
    </MenuCollapseItem>
  );
};

export default MenuAdmin;
