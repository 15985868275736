import { LocationResponse, NotificationContactResponse } from "@@types/locations/location-response.type";
import {
  AssociatedSubRouteTemplate,
  Location,
  NotificationContact,
  NotificationContacts,
} from "@@types/locations/location.type";

function mapResponseToLocation(response: LocationResponse | undefined): Location | undefined {
  if (response === undefined) return undefined;
  return {
    id: response.id,
    address: response.address,
    adr1: response.adr1,
    adr2: response.adr2,
    city: response.city,
    companyName: response.companyName,
    doorNo: response.doorNo,
    lat: response.lat,
    long: response.long,
    locatableId: response.locatableId,
    name: response.name,
    postalCode: response.postalCode,
    province: response.province,
    note: response.note,
    vdqNotes: response.vdqNotes,
    ciWebCategory: response.ciWebCategory,
    fileNumber: response.fileNumber,
    isFromCiWeb: response.isFromCiWeb,
    contracts: response.contracts,
    smsAlertPhoneNumber: response.smsAlertPhoneNumber,
    customerMobilePhoneNumber: response.customerMobilePhoneNumber,
    isFrontLoading: response.isFrontLoading,
    relatedLocations: response.relatedLocations,
    associatedSubRouteTemplates: mapResponsesAssociatedSubRouteTemplatesToLocation(
      response.associatedSubRouteTemplates
    ),
    districtName: response.districtName,
    purchaseOrderNo: response.purchaseOrderNo,
    geolocationType: response.geolocationType,
    notificationContacts: mapResponsesNotificationContacts(response.notificationContacts),
  };
}

function mapResponsesAssociatedSubRouteTemplatesToLocation(
  responses: AssociatedSubRouteTemplate[]
): AssociatedSubRouteTemplate[] {
  return responses.map(mapResponseAssociatedSubRouteTemplate);
}

function mapResponseAssociatedSubRouteTemplate(response: AssociatedSubRouteTemplate): AssociatedSubRouteTemplate {
  return {
    id: response.id,
    masterRouteId: response.masterRouteId,
    completeName: response.completeName,
    contractId: response.contractId,
    customerId: response.customerId,
    customerItemId: response.customerItemId,
  };
}

function mapResponsesNotificationContacts(responses: NotificationContactResponse[]): NotificationContacts {
  return responses.reduce((acc, response, index) => {
    acc[index] = mapResponseNotificationContact(response);
    return acc;
  }, {} as NotificationContacts);
}

function mapResponseNotificationContact(response: NotificationContactResponse): NotificationContact {
  return {
    id: response.id,
    name: response.name,
    smsOrEmail: response.smsOrEmail,
    error: false,
    delete: false,
  };
}

export { mapResponseToLocation, mapResponsesNotificationContacts };
