import React, { ReactElement } from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

export type CollapsableListChildProps = {
  id: number;
  masterRoute: string;
  name: string;
  onDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  count?: number;
  path?: string;
  templateType: string;
};

const CollapsableListChild = ({
  id,
  masterRoute,
  name,
  onDelete,
  count,
  path,
  templateType,
}: CollapsableListChildProps): ReactElement => {
  const navigate = useNavigate();

  const handleGoToPath = (): void => {
    path && navigate(path);
  };

  return (
    <TableRow
      className="link-row"
      key={id}
      sx={{
        backgroundColor: "#f5f5f5 !important",
        "*:nth-of-type(even)": { backgroundColor: "#f5f5f5 !important" },
      }}
      onClick={handleGoToPath}
    >
      <TableCell />
      <TableCell>{name}</TableCell>
      <TableCell>{masterRoute}</TableCell>
      <TableCell>{count || 0}</TableCell>
      <TableCell />
      <TableCell>
        <IconButton
          color="secondary"
          onClick={onDelete}
          size="large"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CollapsableListChild;
