import { useState } from "react";
import { CollapseRows } from "@components/report/financial-report-table/FinancialReportTableBody";
import { useNavigate } from "react-router-dom";

type UseMenu = {
  collapseRows: CollapseRows;
  handleClickMenuOption: (value: string) => void;
  handleCloseNestedMenu: () => void;
};

const useMenu = (onClose: () => void): UseMenu => {
  const [collapseRows, setCollapseRows] = useState<CollapseRows>({});
  const [, setOpenCollapse] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClickOnCollapseRow = (key: string): void => {
    collapseRows[key] = !collapseRows[key];
    setOpenCollapse((prevState) => !prevState);
    setCollapseRows(collapseRows);
  };

  const resetCollapseRows = (fieldsToReset: CollapseRows): void => {
    setCollapseRows((prevState) => ({
      ...prevState,
      ...fieldsToReset,
    }));
  };

  const handleClickMenuOption = (value: string): void => {
    handleClickOnCollapseRow(value);

    switch (value) {
      case "ccav":
      case "pep":
        resetCollapseRows({ sectors: false, adminSectors: false });
        break;
      case "sectors":
        resetCollapseRows({ ccav: false, adminSectors: false });
        break;
      case "adminSectors":
        resetCollapseRows({ ccav: false, sectors: false });
        break;
      case "inventory":
      case "admin":
        // No action needed for these cases
        break;
      default:
        navigate(value);
        onClose();
        resetCollapseRows({
          ccav: false,
          sectors: false,
          adminSectors: false,
          inventory: false,
          admin: false,
          pep: false,
        });
    }
  };

  const handleCloseNestedMenu = (): void => {
    onClose();
    resetCollapseRows({
      ccav: false,
      sectors: false,
      adminSectors: false,
      inventory: false,
      admin: false,
      pep: false,
    });
  };

  return {
    collapseRows,
    handleClickMenuOption,
    handleCloseNestedMenu,
  };
};

export default useMenu;
