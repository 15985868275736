import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";

const ElasticSearchInputWrapper = styled.div`
  position: relative;
  margin-right: 16px;
  margin-bottom: 4px;
  background-color: #f4f4f4;
  border-radius: 5px;

  .elastic-search-input-wrap {
    .elastic-search-input {
      width: 450px;
      height: 44px;
      transition: 0.2s ease;
      border-radius: 5px;
      border: 1px solid #bbb;
      outline: 0;
      padding: 2px 0px 0px 35px;

      &:focus {
        border-color: #006f9c;
      }
    }
  }
`;

const ShowInputBtn = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .search-icon {
    transition: 0.2s ease;
  }

  &:hover {
    ~ .elastic-search-input-wrap {
      .elastic-search-input {
        border-color: #006f9c;
      }
    }

    .search-icon {
      path {
        color: #006f9c;
      }
    }
  }
`;

const ElasticSearchInput = ({ children }) => (
  <ElasticSearchInputWrapper className="elastic-search-input-wrapper">
    <ShowInputBtn
      className="show-input-btn"
      id="show-input-btn"
    >
      <SearchIcon
        className="search-icon elastic-search-icon"
        color="primary"
      />
    </ShowInputBtn>
    {children}
  </ElasticSearchInputWrapper>
);

ElasticSearchInput.defaultProps = {};

ElasticSearchInput.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ElasticSearchInput;
