import { QueryKey } from "@tanstack/react-query";

type CollectionKeys = {
  base: "collections";
  all: () => QueryKey;
  geoCollection: (geoCollectionId: string) => QueryKey;
  collection: (collectionId: string) => QueryKey;
  collectionStreets: (collectionId: string) => QueryKey;
};

const collectionsKeys: CollectionKeys = {
  base: "collections",
  all: () => ["collections"],
  geoCollection: (geoCollectionId: string) => [...collectionsKeys.all(), { geoCollectionId }],
  collection: (collectionId: string) => [...collectionsKeys.all(), { collectionId }],
  collectionStreets: (collectionId: string) => [...collectionsKeys.collection(collectionId), "collection-streets"],
};

export default collectionsKeys;
