import React, { ReactElement } from "react";
import { IconProps } from "@components/icons/Icon.type";
import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";

const MoreDotIcon = ({
  size = defaultIconProps.size,
  fillColor = defaultIconProps.fillColor,
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="7"
        height="26"
        viewBox="0 0 7 26"
        fill={fillColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 54">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 13C0.5 11.3431 1.84315 10 3.5 10C5.15685 10 6.5 11.3431 6.5 13C6.5 14.6569 5.15685 16 3.5 16C1.84315 16 0.5 14.6569 0.5 13Z"
            fill="#3CA3F4"
          />
          <path
            id="Vector (Stroke)_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 3.5C0.5 1.84315 1.84315 0.5 3.5 0.5C5.15685 0.5 6.5 1.84315 6.5 3.5C6.5 5.15685 5.15685 6.5 3.5 6.5C1.84315 6.5 0.5 5.15685 0.5 3.5Z"
            fill="#3CA3F4"
          />
          <path
            id="Vector (Stroke)_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 22.5C0.5 20.8431 1.84315 19.5 3.5 19.5C5.15685 19.5 6.5 20.8431 6.5 22.5C6.5 24.1569 5.15685 25.5 3.5 25.5C1.84315 25.5 0.5 24.1569 0.5 22.5Z"
            fill="#3CA3F4"
          />
        </g>
      </svg>
    </Container>
  );
};

export default MoreDotIcon;
