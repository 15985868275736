import React, { ReactElement } from "react";
import { Backdrop, ListItemButton, ListItemText, Typography } from "@mui/material";
import { List } from "@components/header/menu/Menu.styles";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import useFetchContractRoutesByLoadingType from "@hooks/contract-routes/fetch-ccav-contract-routes.hook";
import MenuDrawerExtension from "@components/header/menu/MenuDrawerExtension";

export type MenuContractItemsProps = {
  open: boolean;
  closeMenu: () => void;
  loadingType: string;
  label: string;
};
const MenuContractItems = ({ open, closeMenu, loadingType, label }: MenuContractItemsProps): ReactElement => {
  const { data = [] } = useFetchContractRoutesByLoadingType(loadingType);
  const navigate = useNavigate();

  const contractRoutesByDistrict = Object.groupBy(data, (contractRoute) => contractRoute.district);

  const handleClickOnContractRoute = (path: string): void => {
    navigate(path);
    closeMenu();
  };

  return (
    <MenuDrawerExtension
      openDrawerExtension={open}
      onClose={closeMenu}
    >
      <Backdrop
        open
        onClick={closeMenu}
        invisible
      />

      <Typography sx={{ pt: 3.5, marginLeft: "315px", size: "16px", lineHeight: "20px", fontWeight: 700 }}>
        {label}
      </Typography>
      <List
        style={{
          overflow: "auto",
          marginLeft: "315px",
        }}
      >
        {Object.keys(contractRoutesByDistrict)
          .sort((a, b) => a.localeCompare(b))
          .map((key) => {
            const name = `${contractRoutesByDistrict[key]?.[0]?.districtCompleteName} (${contractRoutesByDistrict[key]?.[0]?.district})`;
            return (
              <>
                <ListItemText
                  key={key}
                  primary={<Typography variant="caption">{name}</Typography>}
                  sx={{ color: "#0000008A", pt: 2 }}
                />
                {Object.values(contractRoutesByDistrict[key] || []).map((contractRoute) => (
                  <ListItemButton
                    key={contractRoute.id}
                    onClick={() =>
                      handleClickOnContractRoute(
                        `/customers/${contractRoute.customerId}/contracts/${contractRoute.contractId}/preparations/${contractRoute.customerItemId}?current_tab=3`
                      )
                    }
                  >
                    <ListItemText primary={<Trans i18nKey={`matter.code.${contractRoute.matterCode}`} />} />
                  </ListItemButton>
                ))}
              </>
            );
          })}
      </List>
    </MenuDrawerExtension>
  );
};

export default MenuContractItems;
